.image-comparator {
  width: 100%;
  background-color: $front-bg-color;
  color: #ffffff;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__left-date,
  &__right-date {
    position: absolute;
    z-index: 10000;
    padding: 0.8rem 1.8rem;
    top: 2rem;
    @include transparent-bg();

    span {
      position: relative;
      z-index: 10;
      @media (max-width: #{$small-devices}) {
        font-size: 1rem;
      }
    }

    @media (max-width: #{$small-devices}) {
      padding: 0.8rem 1rem;
    }
  }

  &__left-date {
    left: 2rem;
  }

  &__right-date {
    right: 2rem;
  }
}

.comparator__loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
