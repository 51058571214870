.dashboard-container{
  min-height: 90%;
  @include display-flex();
}

.dashboard{
  height: auto;
  width: 80%;
  padding: 2rem;
  box-sizing: border-box;
  @media (max-width: #{$medium-devices}){
    width: 100%;
  }
}

.camera-form__success{
  margin-bottom: 2rem;
  background-color: #d4edda;
  padding: 1rem;
  div{
    margin: 0.5rem 0;
  }
}

.data-table {
  thead {
    background-color: $table-head-color;
    color: #ffffff;
    tr {
      height: 3rem;
    }
  }
  tbody {
    tr:nth-child(even) {
      background-color: $table-row-color;
    }

    td {
      height: 3rem;
      .fas {
        color: $table-head-color;
        margin-left: 1rem;
      }
    }
  }
}

.dashboard-loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $second-color;
    opacity: 0.7;
  }
}

.dashboard-loading__image {
  position: absolute;
  width: 5rem;
  height: 5rem;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 11;
  top: 50%;
  left: 50%;
  margin-left: -2.5rem;
  margin-top: -2.5rem;
}