h1{
  font-size: 2.3rem;
  text-transform: uppercase;
  @extend %font-muli-bold;
}

h3{
  font-size: 1.9rem;
  margin-bottom: 1rem;
  @extend %font-muli-bold;
}