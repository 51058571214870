.time-picker{
  border-radius: 0.4rem;
  padding:3rem;
  box-sizing: border-box;
  font-size: 1rem;
  background-color: $second-color;
  margin-top: 0.1rem;
  @include display-flex();
}

.time-picker__hours,
.time-picker__minutes,
.time-picker__systems{
  width: 30%;
  height: 8rem;
  overflow-y: scroll;
  @include scrollbars(.2rem, #e2e2e2);
  li{
    button{
      border: none;
      width: 100%;
      margin-bottom: 0.2rem;
      font-size: 2rem;
      background-color: $second-color;
      color: $front-font-color;
      cursor: pointer;
      &.selected{
        background-color: $main-color;
        color: $second-color;
        &:hover{
          background-color: $main-color;
          color: $second-color;
        }
      }
      &:hover{
        background-color: #f4f4f4;
        color: $second-color;
      }

      &.time-picker__selected{
        background-color: $main-color;
        color: $second-color;
        &:hover{
          background-color: $main-color;
          color: $second-color;
        }
      }
    }
  }    
}