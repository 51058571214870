.send-email-form{
  @include popup();
}

.send-email-form__container{
  position: relative;
  z-index: 10;
  padding: 2rem;
  box-sizing: border-box;
  width: 100%;
  max-width: 70rem;
  background-color: $bg-dark-popup;
}

.send-email-form__sections{
  border: solid $input-border-color 1px;
  padding: 2rem;
  box-sizing: border-box;
  @media (min-width: #{$small-devices}){
    @include display-flex();
    @include align-items-center();
  }
}

.send-email-form__sender,
.send-email-form__recipient{
  padding: 0.5rem;
  box-sizing: border-box;
  @media (min-width: #{$small-devices}){
    width: 50%;
  }
}

.form-group__description{
  max-width: 100%;
  margin-top: 2rem;
}

.send-email-form__title{
  font-size: 2rem;
  margin-bottom: 2rem;
  text-align: center;
  @extend %font-muli-regular;
}

.send-email-form__close-button{
  position: absolute;
  top:0;
  right: 0;
  cursor: pointer;
  background-color: $main-color;
  border: none;
  padding: 0.2rem 0.5rem;
  i{
    color: #ffffff;
    font-size: 2rem;
  }
}