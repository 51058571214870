.arrows{
  width: 100%;
  height: 15rem;
  .arrows__buttons{
    position: relative;
    height: 7.5rem;
  }
}

.arrows--hidden{
  display: none;
}

.arrows__button {
  background-color: $front-button-color;
  position: absolute;
  text-align: center;
  width: 7.5rem;
  height: 7.5rem;
  z-index: 10000;
  border: none;
  cursor: pointer;
  color: $front-icon-color;
  opacity: 0.7;
  @include display-flex();
  @include align-content-center();
  @include justify-center();
  i{
    font-size: 5rem;
  }
  &:last-child{
    right: 0;
  }
  &:hover{
    opacity: 1;
  }

  &--loading {
    color: $dark-color;
    cursor: auto;
    &:hover {
      color: $dark-color;
      opacity: 0.7;
    }
  }
}

.arrows__button-toggle{
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
  z-index: 10;
  i{
    font-size: 3.5rem;
  }
}