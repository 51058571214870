.reset-password-form{
  height: 100%;
  width: 100%;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  @include transparent-bg();
  @include background-image("../images/login-bg.jpeg");
}

.reset-password-form__go-login {
  color: #ffffff;
  margin-top: 1.5rem;
  display: block;
  z-index: 3;
  position: relative;

  i {
    margin-right: 1rem;
  }
}

.reset-password-form__form-container{
  width: 32rem;
  height: 40rem;
  position: relative;
  z-index: 2;
  text-align: center;
  padding: 3rem 2.5rem;
  border-radius: 2rem;
  overflow: hidden;
  box-sizing: border-box;
  @include transparent-bg();

  form {
    position: relative;
    z-index: 3;
  }

  .big-logo {
    z-index: 3;
    position: relative;
  }

  @media (max-width: #{$small-devices}){
    width: 90%;
  }
}

.reset-password-form__error {
  color: $error;
  margin-bottom: 1rem;
}