.dropdown-view-selector {
  font-family: Arial, sans-serif;
}

.dropdown-view-selector__button {
  background-color: $dark-color;
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.dropdown-view-selector__button:hover {
  background-color: $dark-color;
}

.dropdown-view-selector__menu {
  position: absolute;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin: 0;
  padding: 0;
  list-style: none;
  width: 150px;
  z-index: 1000;
}

.dropdown-view-selector__item {
  margin: 0;
  padding: 0;
}

.dropdown-view-selector__button-option {
  width: 100%;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  gap: 8px;
  background: none;
  border: none;
  text-align: left;
  font-size: 14px;
  cursor: pointer;
}

.dropdown-view-selector__button-option:hover {
  background-color: #f5f5f5;
}

.dropdown-view-selector__button-option--active {
  font-weight: bold;
  background-color: #e9ecef;
}

.dropdown-view-selector__icon {
  font-size: 16px;
}
