.dashboard-menu{
  padding-top: 3rem;
  width: 20%;
  background-color: $second-color;

  @media (max-width: #{$medium-devices}){
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    padding-top: 5rem;
    left: -100vh;
    transition: 1s;
  }

  .accordion{
    width: 100%;
    .accordion__heading{
      color: #ffffff;
      background-color: $second-color;
      display: block;
      padding: 1rem 2rem;
      text-transform: uppercase;
      cursor: pointer;
      position: relative;

      @media (max-width: #{$medium-devices}){
        div {
          font-size: 2rem;
        }
      }

      .fas {
        margin-right: 1rem;
      }

      &:hover{
        &:after{
          content: "";
          background: $main-color;
          position: absolute;
          width: 100%;
          height: 0.3rem;
          bottom: 0;
          left: 0;
        }
      }
    }

    ul{
      padding: 1rem 2rem;
      a, button{
        color: $dark-color;
        position: relative;
        display: block;
        line-height: 2.5rem;
        cursor: pointer;
        background-color: transparent;
        border: none;

        &:hover{
          color: $main-color;
        }

        @media (max-width: #{$medium-devices}){
          font-size: 2rem;
          line-height: 3.5rem;
        }

        .fas {
          margin-right: 1rem;
        }
      }
    }
  }
}

.dashboard-menu--opened {
  left: 0;
  transition: 1s;
}

.dashboard-menu__logo {
  width: 23rem;
  margin-bottom: 2rem;
  display: none;
  @media (max-width: #{$medium-devices}){
    display: inline;
  }
}

.dashboard-menu__close-button {
  background-color: transparent;
  color: #ffffff;
  border: none;
  position: absolute;
  top: 2rem;
  right: 2rem;
  cursor: pointer;
  i {
    font-size: 4rem;
  }
}