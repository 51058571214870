.videos{
  @include popup();
}

.videos__accordion{
  position: relative;
  z-index: 10;
  width: 30rem;
}

.accordion__button{
  box-sizing: border-box;
}

.videos__video-link{
  background: none;
  cursor: pointer;
  color: $front-font-color;
  border: none;
}

.videos__video-popup{
  @include popup();
  display: none;
  position: fixed;
}

.videos__video-popup--visible{
  @include display-flex();
}

.videos__video{
  position: relative;
  z-index: 1;
  text-align: center;
  width: 700px;
  height: 400px;
  .main-button{
    margin-top: 1rem;
  }

  @media (max-width: 700px){
    width: 100%;
    height: 35%;
  }
}

.videos__close-video{
  position: absolute;
  background: none;
  cursor: pointer;
  color: $front-font-color;
  border: none;
  right: -1rem;
  top: -1rem;
  i{
    font-size: 2rem;
    @media (max-width: 700px){
      font-size: 5rem;
    }
  }

  @media (max-width: 700px){
    right: 50%;
    margin-right: -2.5rem;
    top: -6rem;
  }
}

.videos__close-videos{
  position: absolute;
  background: $second-color;
  cursor: pointer;
  color: $front-font-color;
  border: none;
  right: 0.5rem;
  top: 0.5rem;
  padding: 0.2rem 0.4rem;
  i{
    font-size: 2rem;
  }
}