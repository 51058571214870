html,body{
  height: 100%;
  width: 100%;
  font-size: $font-size-base;
}

* {
  margin: 0;
  padding: 0;
  font-size: 1.6rem;
  outline: none;
  @extend %font-noto-sans-regular;
}

a{
  text-decoration: none;
}

ul{
  list-style: none;
}

#root {
  height: 100%;
}