// Responsive
$small-devices: 576px;
$medium-devices: 768px;
$large-devices: 992px;
$extra-large-devices: 1200px;

// Fonts size
$font-size-base: 62.5%;

// Bg Colors
$main-color: #f9be14;
$second-color: #222222;
$front-bg-color: #000000;
$front-button-color: #000000;
$bg-dark-popup: #171212;
$error: #F44336;

// Fonts colors
$front-font-color: #ffffff;
$front-icon-color: #ffffff;
$dark-color: #807a7a;

$opacity: 0.7;

/*
 * Forms colors
 */
$input-border-color: #e1e4e8;
$input-bg-color: #ffffff;
$input-text-color: #222222;

/*
 * Tables
 */
$table-head-color: #222222;
$table-row-color:#d8d8d8;

/*
 * Const for datapicker
 */
$datepicker__background-color: $second-color !default;
$datepicker__border-color: $second-color !default;
$datepicker__muted-color: #696060 !default;
$datepicker__selected-color: $main-color !default;
$datepicker__text-color: $front-font-color !default;
$datepicker__header-color: $front-font-color !default;
$datepicker__navigation-disabled-color: lighten($datepicker__muted-color, 10%) !default;

$datepicker__border-radius: 0.3rem !default;
$datepicker__day-margin: 0.166rem !default;
$datepicker__font-size: 1.6rem !default;
$datepicker__font-family: 'Noto Sans' !default;
$datepicker__item-size: 4rem !default;
$datepicker__margin: 0.4rem !default;
$datepicker__navigation-size: 1rem !default;
$datepicker__triangle-size: 20px !default;