.image-comparator-footer {
  position: fixed;
  bottom: 0;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  padding: 0 2rem;
  box-sizing: border-box;
  overflow: hidden;
  z-index: 10000;
  background-color: transparent;
  width: 60rem;
  height: 8rem;
  justify-content: space-between;
  @include transparent-bg();

  button, a {
    @include footerButton();
  }

  @media (max-width: #{$medium-devices}) {
    background-color: $front-button-color;
    width: 100%;
    justify-content: center;
  }

  &--loading {
    button, a {
      color: $dark-color;
      cursor: auto;
      i {
        &:hover {
          color: $dark-color;
          opacity: 0.7;
        }
      }
    }
  }
}

.image-comparator-footer--opened {
  -webkit-transition: height 0.3s ease-in-out;
  -moz-transition: height 0.3s ease-in-out;
  -o-transition: height 0.3s ease-in-out;
  transition: height 0.3s ease-in-out;
  @media (max-width: #{$medium-devices}){
    height: 8rem;
  }
}

.image-comparator-footer--closed {
  height: 0;
  -webkit-transition: height 0.3s ease-in-out;
  -moz-transition: height 0.3s ease-in-out;
  -o-transition: height 0.3s ease-in-out;
  transition: height 0.3s ease-in-out;
}

.image-comparator-footer__open-button {
  border-radius: 2.5rem;
  border: none;
  width: 4rem;
  height: 4rem;
  cursor: pointer;
  position: absolute;
  bottom: 2rem;
  left: 2rem;
  background-color: $front-icon-color;
  i {
    font-size: 3rem;
  }
}

.image-comparator-footer__center-buttons {
  display: flex;
  gap: 2rem;
}