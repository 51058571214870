@mixin display-flex() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
}

@mixin align-items-center() {
  -webkit-align-items: center;
  align-items: center;
}

@mixin align-content-center() {
  -webkit-content-items: center;
  align-content: center;
}

@mixin justify-center() {
  -webkit-justify-content: center;
  justify-content: center;
}

@mixin justify-right() {
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

@mixin justify-space-between {
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

@mixin transparent-bg(){
  &::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: $second-color;
    opacity: 0.8;
  }
}

@mixin background-image($url){
  background-image: url($url);
  background-repeat: no-repeat;
  background-size: cover;
}

@mixin popup(){
  position: absolute;
  z-index: 100000;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  @include display-flex();
  @include justify-center();
  @include align-items-center();
  &::after{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: $front-bg-color;
    opacity: $opacity;
  }
}

@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white,  50%)) {
  &::-webkit-scrollbar {
    width:  $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }
}

@mixin footerButton() {
  position: relative;
  z-index: 100;
  cursor: pointer;
  border: none;
  color: $front-icon-color;
  background-color: transparent;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  flex-direction: column;
  
  i {
    font-size: 3.5rem;
    opacity: 0.7;
    &:hover{
      opacity: 1;
    }
  }

  span {
    font-size: 1.3rem;
    @media (max-width: #{$medium-devices}) {
      display: none;
    }
  }
}
  