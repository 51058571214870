@import url(https://fonts.googleapis.com/css?family=Muli:400,700,900|Noto+Sans|Heebo:700);
/*
 * Forms colors
 */
/*
 * Tables
 */
/*
 * Const for datapicker
 */
*, .input-text {
  font-family: 'Noto Sans';
  font-weight: 400; }

.send-email-form__title, .projects__project-item button,
.projects__close-session-button button {
  font-family: 'Muli';
  font-weight: 400; }

h1, h3 {
  font-family: 'Muli';
  font-weight: 700; }

.projects__title {
  font-family: 'Muli';
  font-weight: 900; }

html, body {
  height: 100%;
  width: 100%;
  font-size: 62.5%; }

* {
  margin: 0;
  padding: 0;
  font-size: 1.6rem;
  outline: none; }

a {
  text-decoration: none; }

ul {
  list-style: none; }

#root {
  height: 100%; }

.big-logo {
  width: 100%;
  height: 11rem;
  margin-bottom: 2rem;
  background-image: url(/static/media/Logo-fotomanager.1f4c4308.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-size: contain; }

.text-center {
  text-align: center; }

.loading {
  background-image: url(/static/media/loading.08d6db5e.gif);
  display: block;
  width: 5rem;
  height: 5rem;
  background-size: contain;
  margin: auto; }

/*
 * Forms
 */
.input-text {
  width: 100%;
  height: 4.5rem;
  border: 0.1rem solid #e1e4e8;
  font-size: 1.8rem;
  padding: 0 1.5rem;
  box-sizing: border-box;
  background-color: #ffffff;
  color: #222222; }
  .input-text.textarea {
    padding: 1.5rem;
    height: 10rem; }
  .input-text.error {
    border-color: #F44336; }

.form-group {
  margin-bottom: 2rem;
  width: 100%;
  max-width: 50rem;
  position: relative; }
  .form-group.internal-button {
    display: flex;
    flex-wrap: wrap;
    align-items: center; }
  .form-group button {
    position: absolute;
    right: 1rem;
    font-size: 2rem;
    cursor: pointer;
    background: none;
    border: none;
    color: #777777; }
  .form-group label {
    margin-bottom: 0.7rem;
    display: block;
    font-size: 1.8rem; }
  @media (max-width: 768px) {
    .form-group {
      max-width: 100%; } }

.white-button {
  border: solid 0.2rem #222222;
  background-color: #ffffff;
  padding: 1rem 2rem;
  text-transform: uppercase;
  cursor: pointer; }
  .white-button:hover {
    background-color: #f9be14;
    border-color: #f9be14;
    color: #ffffff; }

.main-button {
  border: solid 0.2rem #f9be14;
  background-color: #f9be14;
  padding: 1rem 2rem;
  text-transform: uppercase;
  color: #222222;
  cursor: pointer;
  display: inline-block; }

.form-error {
  color: #F44336;
  font-style: oblique;
  margin: 1rem 0;
  display: block; }

.dashboard-container {
  min-height: 90%;
  display: flex;
  flex-wrap: wrap; }

.dashboard {
  height: auto;
  width: 80%;
  padding: 2rem;
  box-sizing: border-box; }
  @media (max-width: 768px) {
    .dashboard {
      width: 100%; } }

.camera-form__success {
  margin-bottom: 2rem;
  background-color: #d4edda;
  padding: 1rem; }
  .camera-form__success div {
    margin: 0.5rem 0; }

.data-table thead {
  background-color: #222222;
  color: #ffffff; }
  .data-table thead tr {
    height: 3rem; }

.data-table tbody tr:nth-child(even) {
  background-color: #d8d8d8; }

.data-table tbody td {
  height: 3rem; }
  .data-table tbody td .fas {
    color: #222222;
    margin-left: 1rem; }

.dashboard-loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10; }
  .dashboard-loading::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #222222;
    opacity: 0.7; }

.dashboard-loading__image {
  position: absolute;
  width: 5rem;
  height: 5rem;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 11;
  top: 50%;
  left: 50%;
  margin-left: -2.5rem;
  margin-top: -2.5rem; }

.dashboard-header {
  width: 100%;
  height: 10%;
  padding: 0 2rem;
  box-sizing: border-box;
  font-size: 1.8rem;
  border-bottom: solid 0.5rem #222222; }
  @media (max-width: 768px) {
    .dashboard-header {
      height: 4.5rem; } }

.dashboard-header__logo img {
  width: 100%;
  max-width: 22rem; }
  @media (max-width: 768px) {
    .dashboard-header__logo img {
      width: 10rem; } }

.dashboard-header__menu-button {
  display: none;
  background-color: transparent;
  border: none; }
  @media (max-width: 768px) {
    .dashboard-header__menu-button {
      display: inline;
      position: absolute;
      right: 1rem;
      top: 0.7rem;
      cursor: pointer; }
      .dashboard-header__menu-button i {
        font-size: 2.5rem; } }

.dashboard-menu {
  padding-top: 3rem;
  width: 20%;
  background-color: #222222; }
  @media (max-width: 768px) {
    .dashboard-menu {
      position: fixed;
      top: 0;
      width: 100%;
      height: 100vh;
      z-index: 10;
      padding-top: 5rem;
      left: -100vh;
      transition: 1s; } }
  .dashboard-menu .accordion {
    width: 100%; }
    .dashboard-menu .accordion .accordion__heading {
      color: #ffffff;
      background-color: #222222;
      display: block;
      padding: 1rem 2rem;
      text-transform: uppercase;
      cursor: pointer;
      position: relative; }
      @media (max-width: 768px) {
        .dashboard-menu .accordion .accordion__heading div {
          font-size: 2rem; } }
      .dashboard-menu .accordion .accordion__heading .fas {
        margin-right: 1rem; }
      .dashboard-menu .accordion .accordion__heading:hover:after {
        content: "";
        background: #f9be14;
        position: absolute;
        width: 100%;
        height: 0.3rem;
        bottom: 0;
        left: 0; }
    .dashboard-menu .accordion ul {
      padding: 1rem 2rem; }
      .dashboard-menu .accordion ul a, .dashboard-menu .accordion ul button {
        color: #807a7a;
        position: relative;
        display: block;
        line-height: 2.5rem;
        cursor: pointer;
        background-color: transparent;
        border: none; }
        .dashboard-menu .accordion ul a:hover, .dashboard-menu .accordion ul button:hover {
          color: #f9be14; }
        @media (max-width: 768px) {
          .dashboard-menu .accordion ul a, .dashboard-menu .accordion ul button {
            font-size: 2rem;
            line-height: 3.5rem; } }
        .dashboard-menu .accordion ul a .fas, .dashboard-menu .accordion ul button .fas {
          margin-right: 1rem; }

.dashboard-menu--opened {
  left: 0;
  transition: 1s; }

.dashboard-menu__logo {
  width: 23rem;
  margin-bottom: 2rem;
  display: none; }
  @media (max-width: 768px) {
    .dashboard-menu__logo {
      display: inline; } }

.dashboard-menu__close-button {
  background-color: transparent;
  color: #ffffff;
  border: none;
  position: absolute;
  top: 2rem;
  right: 2rem;
  cursor: pointer; }
  .dashboard-menu__close-button i {
    font-size: 4rem; }

h1 {
  font-size: 2.3rem;
  text-transform: uppercase; }

h3 {
  font-size: 1.9rem;
  margin-bottom: 1rem; }

.login {
  height: 100%;
  width: 100%;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(/static/media/login-bg.67b4b350.jpeg);
  background-repeat: no-repeat;
  background-size: cover; }
  .login::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: #222222;
    opacity: 0.8; }
  .login a {
    display: block;
    margin-top: 2rem;
    color: #ffffff; }

.login__form-container {
  width: 32rem;
  height: 40rem;
  position: relative;
  z-index: 2;
  text-align: center;
  padding: 3rem 2.5rem;
  border-radius: 2rem;
  overflow: hidden;
  box-sizing: border-box; }
  .login__form-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: #222222;
    opacity: 0.8; }
  .login__form-container form {
    position: relative;
    z-index: 3; }
  @media (max-width: 576px) {
    .login__form-container {
      width: 90%; } }

.login__forgot-password-button {
  background: none;
  border: none;
  color: #ffffff;
  cursor: pointer;
  display: block;
  width: 100%;
  margin-top: 1.5rem; }

.camera {
  height: 100%;
  background-color: #000000;
  color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  align-items: center; }

.camera__date {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  z-index: 10000;
  padding: 0.8rem 1.8rem; }
  .camera__date::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: #222222;
    opacity: 0.8; }
  .camera__date span {
    position: relative;
    z-index: 10; }
  @media (max-width: 576px) {
    .camera__date {
      padding: 0.8rem 1rem; }
      .camera__date span {
        font-size: 1rem; } }

.image__container {
  width: 100%;
  height: 100%;
  position: absolute; }
  .image__container--loading {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black; }

.footer {
  background-color: #000000;
  position: absolute;
  width: 60rem;
  left: 50%;
  margin-left: -30rem;
  bottom: 0;
  z-index: 10000;
  height: 8rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center; }
  .footer::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: #222222;
    opacity: 0.8; }
  @media (max-width: 1200px) {
    .footer {
      position: fixed;
      overflow: hidden;
      border-top: 0.2rem solid #222222;
      width: 100%;
      left: 0;
      margin-left: 0; } }

.footer__button {
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: #ffffff;
  margin-right: 5rem;
  z-index: 10; }
  .footer__button i {
    font-size: 3.5rem;
    opacity: 0.7; }
    .footer__button i:hover {
      opacity: 1; }
  .footer__button:last-child {
    margin: 0; }
  .footer__button--loading {
    color: #807a7a;
    cursor: auto; }
    .footer__button--loading i:hover {
      color: #807a7a;
      opacity: 0.7; }
  @media (max-width: 576px) {
    .footer__button {
      border: 0.2rem solid #222222;
      margin-right: 1rem; } }

.footer__open-button {
  border-radius: 2.5rem;
  border: none;
  width: 4rem;
  height: 4rem;
  cursor: pointer;
  position: absolute;
  bottom: 2rem;
  left: 2rem;
  background-color: #ffffff; }
  .footer__open-button i {
    font-size: 3rem; }

.footer--opened {
  transition: height 0.3s ease-in-out; }
  @media (max-width: 1200px) {
    .footer--opened {
      height: 8rem; } }

.footer--closed {
  height: 0;
  transition: height 0.3s ease-in-out; }

.calendar {
  position: absolute;
  z-index: 100000;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center; }
  .calendar::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #000000;
    opacity: 0.7; }

.react-datepicker__header__dropdown {
  margin: 1.5rem 0 0.5rem 0; }

.calendar__calendar-time-picker {
  position: relative;
  z-index: 1; }

.react-datepicker__month-container {
  background-color: #222222; }

.calendar_buttons {
  background-color: #222222;
  justify-content: space-around;
  padding: 1rem;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap; }

.time-picker {
  border-radius: 0.4rem;
  padding: 3rem;
  box-sizing: border-box;
  font-size: 1rem;
  background-color: #222222;
  margin-top: 0.1rem;
  display: flex;
  flex-wrap: wrap; }

.time-picker__hours,
.time-picker__minutes,
.time-picker__systems {
  width: 30%;
  height: 8rem;
  overflow-y: scroll; }
  .time-picker__hours::-webkit-scrollbar,
  .time-picker__minutes::-webkit-scrollbar,
  .time-picker__systems::-webkit-scrollbar {
    width: 0.2rem;
    height: 0.2rem; }
  .time-picker__hours::-webkit-scrollbar-thumb,
  .time-picker__minutes::-webkit-scrollbar-thumb,
  .time-picker__systems::-webkit-scrollbar-thumb {
    background: #e2e2e2; }
  .time-picker__hours::-webkit-scrollbar-track,
  .time-picker__minutes::-webkit-scrollbar-track,
  .time-picker__systems::-webkit-scrollbar-track {
    background: #f1f1f1; }
  .time-picker__hours li button,
  .time-picker__minutes li button,
  .time-picker__systems li button {
    border: none;
    width: 100%;
    margin-bottom: 0.2rem;
    font-size: 2rem;
    background-color: #222222;
    color: #ffffff;
    cursor: pointer; }
    .time-picker__hours li button.selected,
    .time-picker__minutes li button.selected,
    .time-picker__systems li button.selected {
      background-color: #f9be14;
      color: #222222; }
      .time-picker__hours li button.selected:hover,
      .time-picker__minutes li button.selected:hover,
      .time-picker__systems li button.selected:hover {
        background-color: #f9be14;
        color: #222222; }
    .time-picker__hours li button:hover,
    .time-picker__minutes li button:hover,
    .time-picker__systems li button:hover {
      background-color: #f4f4f4;
      color: #222222; }
    .time-picker__hours li button.time-picker__selected,
    .time-picker__minutes li button.time-picker__selected,
    .time-picker__systems li button.time-picker__selected {
      background-color: #f9be14;
      color: #222222; }
      .time-picker__hours li button.time-picker__selected:hover,
      .time-picker__minutes li button.time-picker__selected:hover,
      .time-picker__systems li button.time-picker__selected:hover {
        background-color: #f9be14;
        color: #222222; }

.arrows {
  width: 100%;
  height: 15rem; }
  .arrows .arrows__buttons {
    position: relative;
    height: 7.5rem; }

.arrows--hidden {
  display: none; }

.arrows__button {
  background-color: #000000;
  position: absolute;
  text-align: center;
  width: 7.5rem;
  height: 7.5rem;
  z-index: 10000;
  border: none;
  cursor: pointer;
  color: #ffffff;
  opacity: 0.7;
  display: flex;
  flex-wrap: wrap;
  -webkit-content-items: center;
  align-content: center;
  justify-content: center; }
  .arrows__button i {
    font-size: 5rem; }
  .arrows__button:last-child {
    right: 0; }
  .arrows__button:hover {
    opacity: 1; }
  .arrows__button--loading {
    color: #807a7a;
    cursor: auto; }
    .arrows__button--loading:hover {
      color: #807a7a;
      opacity: 0.7; }

.arrows__button-toggle {
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
  z-index: 10; }
  .arrows__button-toggle i {
    font-size: 3.5rem; }

.videos {
  position: absolute;
  z-index: 100000;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center; }
  .videos::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #000000;
    opacity: 0.7; }

.videos__accordion {
  position: relative;
  z-index: 10;
  width: 30rem; }

.accordion__button {
  box-sizing: border-box; }

.videos__video-link {
  background: none;
  cursor: pointer;
  color: #ffffff;
  border: none; }

.videos__video-popup {
  position: absolute;
  z-index: 100000;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  display: none;
  position: fixed; }
  .videos__video-popup::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #000000;
    opacity: 0.7; }

.videos__video-popup--visible {
  display: flex;
  flex-wrap: wrap; }

.videos__video {
  position: relative;
  z-index: 1;
  text-align: center;
  width: 700px;
  height: 400px; }
  .videos__video .main-button {
    margin-top: 1rem; }
  @media (max-width: 700px) {
    .videos__video {
      width: 100%;
      height: 35%; } }

.videos__close-video {
  position: absolute;
  background: none;
  cursor: pointer;
  color: #ffffff;
  border: none;
  right: -1rem;
  top: -1rem; }
  .videos__close-video i {
    font-size: 2rem; }
    @media (max-width: 700px) {
      .videos__close-video i {
        font-size: 5rem; } }
  @media (max-width: 700px) {
    .videos__close-video {
      right: 50%;
      margin-right: -2.5rem;
      top: -6rem; } }

.videos__close-videos {
  position: absolute;
  background: #222222;
  cursor: pointer;
  color: #ffffff;
  border: none;
  right: 0.5rem;
  top: 0.5rem;
  padding: 0.2rem 0.4rem; }
  .videos__close-videos i {
    font-size: 2rem; }

.send-email-form {
  position: absolute;
  z-index: 100000;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center; }
  .send-email-form::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #000000;
    opacity: 0.7; }

.send-email-form__container {
  position: relative;
  z-index: 10;
  padding: 2rem;
  box-sizing: border-box;
  width: 100%;
  max-width: 70rem;
  background-color: #171212; }

.send-email-form__sections {
  border: solid #e1e4e8 1px;
  padding: 2rem;
  box-sizing: border-box; }
  @media (min-width: 576px) {
    .send-email-form__sections {
      display: flex;
      flex-wrap: wrap;
      align-items: center; } }

.send-email-form__sender,
.send-email-form__recipient {
  padding: 0.5rem;
  box-sizing: border-box; }
  @media (min-width: 576px) {
    .send-email-form__sender,
    .send-email-form__recipient {
      width: 50%; } }

.form-group__description {
  max-width: 100%;
  margin-top: 2rem; }

.send-email-form__title {
  font-size: 2rem;
  margin-bottom: 2rem;
  text-align: center; }

.send-email-form__close-button {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  background-color: #f9be14;
  border: none;
  padding: 0.2rem 0.5rem; }
  .send-email-form__close-button i {
    color: #ffffff;
    font-size: 2rem; }

.forgot-password-form {
  position: absolute;
  z-index: 100000;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center; }
  .forgot-password-form::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #000000;
    opacity: 0.7; }

.forgot-password-form__container {
  position: relative;
  z-index: 10;
  background-color: #ffffff;
  padding: 2rem;
  width: 30rem; }

.forgot-password-form__close-button {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  background-color: #f9be14;
  border: none;
  padding: 0.2rem 0.5rem; }
  .forgot-password-form__close-button i {
    color: #ffffff;
    font-size: 2rem; }

.reset-password-form {
  height: 100%;
  width: 100%;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(/static/media/login-bg.67b4b350.jpeg);
  background-repeat: no-repeat;
  background-size: cover; }
  .reset-password-form::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: #222222;
    opacity: 0.8; }

.reset-password-form__go-login {
  color: #ffffff;
  margin-top: 1.5rem;
  display: block;
  z-index: 3;
  position: relative; }
  .reset-password-form__go-login i {
    margin-right: 1rem; }

.reset-password-form__form-container {
  width: 32rem;
  height: 40rem;
  position: relative;
  z-index: 2;
  text-align: center;
  padding: 3rem 2.5rem;
  border-radius: 2rem;
  overflow: hidden;
  box-sizing: border-box; }
  .reset-password-form__form-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: #222222;
    opacity: 0.8; }
  .reset-password-form__form-container form {
    position: relative;
    z-index: 3; }
  .reset-password-form__form-container .big-logo {
    z-index: 3;
    position: relative; }
  @media (max-width: 576px) {
    .reset-password-form__form-container {
      width: 90%; } }

.reset-password-form__error {
  color: #F44336;
  margin-bottom: 1rem; }

.image-comparator {
  width: 100%;
  background-color: #000000;
  color: #ffffff;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }
  .image-comparator__left-date, .image-comparator__right-date {
    position: absolute;
    z-index: 10000;
    padding: 0.8rem 1.8rem;
    top: 2rem; }
    .image-comparator__left-date::before, .image-comparator__right-date::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background-color: #222222;
      opacity: 0.8; }
    .image-comparator__left-date span, .image-comparator__right-date span {
      position: relative;
      z-index: 10; }
      @media (max-width: 576px) {
        .image-comparator__left-date span, .image-comparator__right-date span {
          font-size: 1rem; } }
    @media (max-width: 576px) {
      .image-comparator__left-date, .image-comparator__right-date {
        padding: 0.8rem 1rem; } }
  .image-comparator__left-date {
    left: 2rem; }
  .image-comparator__right-date {
    right: 2rem; }

.comparator__loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center; }

.image-comparator-footer {
  position: fixed;
  bottom: 0;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  padding: 0 2rem;
  box-sizing: border-box;
  overflow: hidden;
  z-index: 10000;
  background-color: transparent;
  width: 60rem;
  height: 8rem;
  justify-content: space-between; }
  .image-comparator-footer::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: #222222;
    opacity: 0.8; }
  .image-comparator-footer button, .image-comparator-footer a {
    position: relative;
    z-index: 100;
    cursor: pointer;
    border: none;
    color: #ffffff;
    background-color: transparent;
    display: flex;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    align-items: center;
    flex-direction: column; }
    .image-comparator-footer button i, .image-comparator-footer a i {
      font-size: 3.5rem;
      opacity: 0.7; }
      .image-comparator-footer button i:hover, .image-comparator-footer a i:hover {
        opacity: 1; }
    .image-comparator-footer button span, .image-comparator-footer a span {
      font-size: 1.3rem; }
      @media (max-width: 768px) {
        .image-comparator-footer button span, .image-comparator-footer a span {
          display: none; } }
  @media (max-width: 768px) {
    .image-comparator-footer {
      background-color: #000000;
      width: 100%;
      justify-content: center; } }
  .image-comparator-footer--loading button, .image-comparator-footer--loading a {
    color: #807a7a;
    cursor: auto; }
    .image-comparator-footer--loading button i:hover, .image-comparator-footer--loading a i:hover {
      color: #807a7a;
      opacity: 0.7; }

.image-comparator-footer--opened {
  transition: height 0.3s ease-in-out; }
  @media (max-width: 768px) {
    .image-comparator-footer--opened {
      height: 8rem; } }

.image-comparator-footer--closed {
  height: 0;
  transition: height 0.3s ease-in-out; }

.image-comparator-footer__open-button {
  border-radius: 2.5rem;
  border: none;
  width: 4rem;
  height: 4rem;
  cursor: pointer;
  position: absolute;
  bottom: 2rem;
  left: 2rem;
  background-color: #ffffff; }
  .image-comparator-footer__open-button i {
    font-size: 3rem; }

.image-comparator-footer__center-buttons {
  display: flex;
  grid-gap: 2rem;
  gap: 2rem; }

.projects {
  background-color: #000000;
  color: #ffffff;
  display: flex;
  min-height: 100%;
  overflow: hidden; }
  .projects__sidebar__header {
    display: flex;
    align-items: center;
    justify-content: space-between; }

.project__content {
  width: 80%; }
  @media (max-width: 992px) {
    .project__content {
      width: 100%; } }

.projects__sidebar {
  padding: 2rem 3rem;
  background-color: #222222;
  width: 20%;
  position: relative;
  min-height: 100vh; }
  .projects__sidebar img {
    max-width: 12rem;
    width: 100%; }
  @media (max-width: 992px) {
    .projects__sidebar {
      position: fixed;
      top: 0;
      left: -100vh;
      transition: 1s;
      height: 100vh;
      z-index: 2000;
      width: 40%; }
      .projects__sidebar--open {
        left: 0;
        transition: 1s; } }
  @media (max-width: 576px) {
    .projects__sidebar {
      width: 100%;
      box-sizing: border-box; } }

.projects__sidebar__menu-button {
  display: none;
  background-color: transparent;
  border: none;
  position: absolute;
  right: 1rem;
  top: 0.7rem;
  z-index: 1000;
  cursor: pointer;
  color: #ffffff; }
  .projects__sidebar__menu-button i {
    font-size: 3rem; }
  @media (max-width: 992px) {
    .projects__sidebar__menu-button {
      display: inline; } }

.projects__sidebar__close-button {
  background-color: transparent;
  color: #ffffff;
  border: none;
  position: absolute;
  top: 2rem;
  right: 2rem;
  cursor: pointer;
  display: none; }
  .projects__sidebar__close-button i {
    font-size: 3rem; }
  @media (max-width: 992px) {
    .projects__sidebar__close-button {
      display: inline; } }

.projects__title {
  font-size: 1.5rem;
  margin: 3rem 0 2rem 0;
  text-transform: uppercase; }

.projects__project-item,
.projects__close-session-button {
  padding: 0.8rem 0; }
  .projects__project-item button,
  .projects__close-session-button button {
    color: #ffffff;
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-align: start; }
    .projects__project-item button i,
    .projects__close-session-button button i {
      margin-right: 1rem; }
    .projects__project-item button:hover,
    .projects__close-session-button button:hover {
      color: #807a7a; }
  .projects__project-item--selected button,
  .projects__close-session-button--selected button {
    color: #807a7a; }

.projects__close-session-button {
  position: fixed;
  bottom: 1rem; }

.projects__cameras-container {
  padding: 1rem;
  display: flex;
  flex-wrap: wrap; }
  @media (max-width: 992px) {
    .projects__cameras-container {
      padding: 6rem 1rem 1rem 1rem; } }

.projects__cameras-loading {
  width: 100%;
  height: calc(100Vh - 2rem);
  display: flex;
  align-items: center;
  justify-content: center; }

.project__camera {
  width: 100%;
  border: solid #ffffff 0.3rem;
  box-sizing: border-box;
  cursor: pointer;
  aspect-ratio: 16 / 9;
  position: relative;
  margin: 0.4rem; }
  .project__camera--grid {
    width: 32%; }
  .project__camera button {
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #ffffff;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    box-sizing: border-box; }
    .project__camera button img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute; }
  .project__camera .project__title {
    position: relative;
    padding: 0.8rem 1rem;
    background-color: #222222; }
    .project__camera .project__title span {
      position: relative;
      display: block;
      z-index: 105;
      text-shadow: 0px 2px 4px black;
      text-align: center;
      font-size: 1.3rem; }
  @media (max-width: 992px) {
    .project__camera--grid {
      width: 48%; } }
  @media (max-width: 576px) {
    .project__camera {
      width: 100%; } }

.projects__dropdown-view-selector--mobile {
  display: none;
  position: absolute;
  right: 5rem;
  top: 0.5rem; }
  @media (max-width: 992px) {
    .projects__dropdown-view-selector--mobile {
      display: inline-block; } }
  @media (max-width: 768px) {
    .projects__dropdown-view-selector--mobile {
      display: none; } }

.projects__dropdown-view-selector--desktop {
  display: none;
  position: relative; }
  @media screen and (min-width: 992px) {
    .projects__dropdown-view-selector--desktop {
      display: inline-block; } }

.projects__mobile-logo {
  display: none;
  max-width: 13rem;
  position: absolute;
  top: 1.4rem;
  left: 1.4rem; }
  @media (max-width: 992px) {
    .projects__mobile-logo {
      display: inline-block; } }

.dropdown-view-selector {
  font-family: Arial, sans-serif; }

.dropdown-view-selector__button {
  background-color: #807a7a;
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px; }

.dropdown-view-selector__button:hover {
  background-color: #807a7a; }

.dropdown-view-selector__menu {
  position: absolute;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin: 0;
  padding: 0;
  list-style: none;
  width: 150px;
  z-index: 1000; }

.dropdown-view-selector__item {
  margin: 0;
  padding: 0; }

.dropdown-view-selector__button-option {
  width: 100%;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  background: none;
  border: none;
  text-align: left;
  font-size: 14px;
  cursor: pointer; }

.dropdown-view-selector__button-option:hover {
  background-color: #f5f5f5; }

.dropdown-view-selector__button-option--active {
  font-weight: bold;
  background-color: #e9ecef; }

.dropdown-view-selector__icon {
  font-size: 16px; }

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  margin-left: -20px;
  position: absolute; }
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
  .react-datepicker__month-read-view--down-arrow::before,
  .react-datepicker__month-year-read-view--down-arrow::before {
    box-sizing: content-box;
    position: absolute;
    border: 20px solid transparent;
    height: 0;
    width: 1px; }
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
  .react-datepicker__month-read-view--down-arrow::before,
  .react-datepicker__month-year-read-view--down-arrow::before {
    content: "";
    z-index: -1;
    border-width: 20px;
    left: -20px;
    border-bottom-color: #222222; }

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  top: 0;
  margin-top: -20px; }
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    border-top: none;
    border-bottom-color: #222222; }
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    top: -1px;
    border-bottom-color: #222222; }

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  bottom: 0;
  margin-bottom: -20px; }
  .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
  .react-datepicker__month-read-view--down-arrow::before,
  .react-datepicker__month-year-read-view--down-arrow::before {
    border-bottom: none;
    border-top-color: #fff; }
  .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
  .react-datepicker__month-read-view--down-arrow::before,
  .react-datepicker__month-year-read-view--down-arrow::before {
    bottom: -1px;
    border-top-color: #222222; }

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0; }

.react-datepicker {
  font-family: "Noto Sans";
  font-size: 1.6rem;
  background-color: #fff;
  color: #ffffff;
  border: 1px solid #222222;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative; }

.react-datepicker--time-only .react-datepicker__triangle {
  left: 35px; }

.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0; }

.react-datepicker--time-only .react-datepicker__time,
.react-datepicker--time-only .react-datepicker__time-box {
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.react-datepicker__triangle {
  position: absolute;
  left: 50px; }

.react-datepicker-popper {
  z-index: 1; }
  .react-datepicker-popper[data-placement^="bottom"] {
    margin-top: 22px; }
  .react-datepicker-popper[data-placement="bottom-end"] .react-datepicker__triangle, .react-datepicker-popper[data-placement="top-end"] .react-datepicker__triangle {
    left: auto;
    right: 50px; }
  .react-datepicker-popper[data-placement^="top"] {
    margin-bottom: 22px; }
  .react-datepicker-popper[data-placement^="right"] {
    margin-left: 20px; }
    .react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle {
      left: auto;
      right: 42px; }
  .react-datepicker-popper[data-placement^="left"] {
    margin-right: 20px; }
    .react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle {
      left: 42px;
      right: auto; }

.react-datepicker__header {
  text-align: center;
  background-color: #222222;
  border-bottom: 1px solid #222222;
  border-top-left-radius: 0.3rem;
  padding-top: 8px;
  position: relative; }
  .react-datepicker__header--time {
    padding-bottom: 8px;
    padding-left: 5px;
    padding-right: 5px; }
    .react-datepicker__header--time:not(.react-datepicker__header--time--only) {
      border-top-left-radius: 0; }
  .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
    border-top-right-radius: 0.3rem; }

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 2px; }

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #ffffff;
  font-weight: bold;
  font-size: 1.888rem; }

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.react-datepicker__navigation {
  background: none;
  line-height: 4rem;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 10px;
  width: 0;
  padding: 0;
  border: 1rem solid transparent;
  z-index: 1;
  height: 10px;
  width: 10px;
  text-indent: -999em;
  overflow: hidden; }
  .react-datepicker__navigation--previous {
    left: 10px;
    border-right-color: #696060; }
    .react-datepicker__navigation--previous:hover {
      border-right-color: #4e4848; }
    .react-datepicker__navigation--previous--disabled, .react-datepicker__navigation--previous--disabled:hover {
      border-right-color: #847878;
      cursor: default; }
  .react-datepicker__navigation--next {
    right: 10px;
    border-left-color: #696060; }
    .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
      right: 95px; }
    .react-datepicker__navigation--next:hover {
      border-left-color: #4e4848; }
    .react-datepicker__navigation--next--disabled, .react-datepicker__navigation--next--disabled:hover {
      border-left-color: #847878;
      cursor: default; }
  .react-datepicker__navigation--years {
    position: relative;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto; }
    .react-datepicker__navigation--years-previous {
      top: 4px;
      border-top-color: #696060; }
      .react-datepicker__navigation--years-previous:hover {
        border-top-color: #4e4848; }
    .react-datepicker__navigation--years-upcoming {
      top: -4px;
      border-bottom-color: #696060; }
      .react-datepicker__navigation--years-upcoming:hover {
        border-bottom-color: #4e4848; }

.react-datepicker__month-container {
  float: left; }

.react-datepicker__year {
  margin: 0.4rem;
  text-align: center; }
  .react-datepicker__year-wrapper {
    display: flex;
    flex-wrap: wrap;
    max-width: 180px; }
  .react-datepicker__year .react-datepicker__year-text {
    display: inline-block;
    width: 4rem;
    margin: 2px; }

.react-datepicker__month {
  margin: 0.4rem;
  text-align: center; }
  .react-datepicker__month .react-datepicker__month-text,
  .react-datepicker__month .react-datepicker__quarter-text {
    display: inline-block;
    width: 4rem;
    margin: 2px; }

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  margin: 5px 0 10px 15px;
  text-align: left; }
  .react-datepicker__input-time-container .react-datepicker-time__caption {
    display: inline-block; }
  .react-datepicker__input-time-container .react-datepicker-time__input-container {
    display: inline-block; }
    .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
      display: inline-block;
      margin-left: 10px; }
      .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
        width: 85px; }
      .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-inner-spin-button,
      .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0; }
      .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"] {
        -moz-appearance: textfield; }
    .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
      margin-left: 5px;
      display: inline-block; }

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid #222222;
  width: 85px; }
  .react-datepicker__time-container--with-today-button {
    display: inline;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    position: absolute;
    right: -72px;
    top: 0; }
  .react-datepicker__time-container .react-datepicker__time {
    position: relative;
    background: white;
    border-bottom-right-radius: 0.3rem; }
    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
      width: 85px;
      overflow-x: hidden;
      margin: 0 auto;
      text-align: center;
      border-bottom-right-radius: 0.3rem; }
      .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
        list-style: none;
        margin: 0;
        height: calc(195px + (4rem / 2));
        overflow-y: scroll;
        padding-right: 0px;
        padding-left: 0px;
        width: 100%;
        box-sizing: content-box; }
        .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
          height: 30px;
          padding: 5px 10px;
          white-space: nowrap; }
          .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
            cursor: pointer;
            background-color: #222222; }
          .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
            background-color: #f9be14;
            color: white;
            font-weight: bold; }
            .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
              background-color: #f9be14; }
          .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
            color: #696060; }
            .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
              cursor: default;
              background-color: transparent; }

.react-datepicker__week-number {
  color: #696060;
  display: inline-block;
  width: 4rem;
  line-height: 4rem;
  text-align: center;
  margin: 0.166rem; }
  .react-datepicker__week-number.react-datepicker__week-number--clickable {
    cursor: pointer; }
    .react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
      border-radius: 0.3rem;
      background-color: #222222; }

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap; }

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #ffffff;
  display: inline-block;
  width: 4rem;
  line-height: 4rem;
  text-align: center;
  margin: 0.166rem; }

.react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range,
.react-datepicker__quarter--selected,
.react-datepicker__quarter--in-selecting-range,
.react-datepicker__quarter--in-range {
  border-radius: 0.3rem;
  background-color: #f9be14;
  color: #fff; }
  .react-datepicker__month--selected:hover, .react-datepicker__month--in-selecting-range:hover, .react-datepicker__month--in-range:hover,
  .react-datepicker__quarter--selected:hover,
  .react-datepicker__quarter--in-selecting-range:hover,
  .react-datepicker__quarter--in-range:hover {
    background-color: #edb206; }

.react-datepicker__month--disabled,
.react-datepicker__quarter--disabled {
  color: #696060;
  pointer-events: none; }
  .react-datepicker__month--disabled:hover,
  .react-datepicker__quarter--disabled:hover {
    cursor: default;
    background-color: transparent; }

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  cursor: pointer; }
  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    border-radius: 0.3rem;
    background-color: #222222; }
  .react-datepicker__day--today,
  .react-datepicker__month-text--today,
  .react-datepicker__quarter-text--today,
  .react-datepicker__year-text--today {
    font-weight: bold; }
  .react-datepicker__day--highlighted,
  .react-datepicker__month-text--highlighted,
  .react-datepicker__quarter-text--highlighted,
  .react-datepicker__year-text--highlighted {
    border-radius: 0.3rem;
    background-color: #3dcc4a;
    color: #fff; }
    .react-datepicker__day--highlighted:hover,
    .react-datepicker__month-text--highlighted:hover,
    .react-datepicker__quarter-text--highlighted:hover,
    .react-datepicker__year-text--highlighted:hover {
      background-color: #32be3f; }
    .react-datepicker__day--highlighted-custom-1,
    .react-datepicker__month-text--highlighted-custom-1,
    .react-datepicker__quarter-text--highlighted-custom-1,
    .react-datepicker__year-text--highlighted-custom-1 {
      color: magenta; }
    .react-datepicker__day--highlighted-custom-2,
    .react-datepicker__month-text--highlighted-custom-2,
    .react-datepicker__quarter-text--highlighted-custom-2,
    .react-datepicker__year-text--highlighted-custom-2 {
      color: green; }
  .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    border-radius: 0.3rem;
    background-color: #f9be14;
    color: #fff; }
    .react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover,
    .react-datepicker__month-text--selected:hover,
    .react-datepicker__month-text--in-selecting-range:hover,
    .react-datepicker__month-text--in-range:hover,
    .react-datepicker__quarter-text--selected:hover,
    .react-datepicker__quarter-text--in-selecting-range:hover,
    .react-datepicker__quarter-text--in-range:hover,
    .react-datepicker__year-text--selected:hover,
    .react-datepicker__year-text--in-selecting-range:hover,
    .react-datepicker__year-text--in-range:hover {
      background-color: #edb206; }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    border-radius: 0.3rem;
    background-color: #facc46;
    color: #fff; }
    .react-datepicker__day--keyboard-selected:hover,
    .react-datepicker__month-text--keyboard-selected:hover,
    .react-datepicker__quarter-text--keyboard-selected:hover,
    .react-datepicker__year-text--keyboard-selected:hover {
      background-color: #edb206; }
  .react-datepicker__day--in-selecting-range ,
  .react-datepicker__month-text--in-selecting-range ,
  .react-datepicker__quarter-text--in-selecting-range ,
  .react-datepicker__year-text--in-selecting-range {
    background-color: rgba(249, 190, 20, 0.5); }
  .react-datepicker__month--selecting-range .react-datepicker__day--in-range , .react-datepicker__month--selecting-range
  .react-datepicker__month-text--in-range , .react-datepicker__month--selecting-range
  .react-datepicker__quarter-text--in-range , .react-datepicker__month--selecting-range
  .react-datepicker__year-text--in-range {
    background-color: #222222;
    color: #ffffff; }
  .react-datepicker__day--disabled,
  .react-datepicker__month-text--disabled,
  .react-datepicker__quarter-text--disabled,
  .react-datepicker__year-text--disabled {
    cursor: default;
    color: #696060; }
    .react-datepicker__day--disabled:hover,
    .react-datepicker__month-text--disabled:hover,
    .react-datepicker__quarter-text--disabled:hover,
    .react-datepicker__year-text--disabled:hover {
      background-color: transparent; }

.react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover, .react-datepicker__month-text.react-datepicker__quarter--selected:hover, .react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__month--selected:hover,
.react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
  background-color: #f9be14; }

.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
  background-color: #222222; }

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 100%; }

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: 0.3rem; }
  .react-datepicker__year-read-view:hover,
  .react-datepicker__month-read-view:hover,
  .react-datepicker__month-year-read-view:hover {
    cursor: pointer; }
    .react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
      border-top-color: #4e4848; }
  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow {
    border-top-color: #696060;
    float: right;
    margin-left: 20px;
    top: 8px;
    position: relative;
    border-width: 1rem; }

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: #222222;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0.3rem;
  border: 1px solid #222222; }
  .react-datepicker__year-dropdown:hover,
  .react-datepicker__month-dropdown:hover,
  .react-datepicker__month-year-dropdown:hover {
    cursor: pointer; }
  .react-datepicker__year-dropdown--scrollable,
  .react-datepicker__month-dropdown--scrollable,
  .react-datepicker__month-year-dropdown--scrollable {
    height: 150px;
    overflow-y: scroll; }

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .react-datepicker__year-option:first-of-type,
  .react-datepicker__month-option:first-of-type,
  .react-datepicker__month-year-option:first-of-type {
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem; }
  .react-datepicker__year-option:last-of-type,
  .react-datepicker__month-option:last-of-type,
  .react-datepicker__month-year-option:last-of-type {
    -webkit-user-select: none;
    user-select: none;
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem; }
  .react-datepicker__year-option:hover,
  .react-datepicker__month-option:hover,
  .react-datepicker__month-year-option:hover {
    background-color: #696060; }
    .react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
    .react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
    .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
      border-bottom-color: #4e4848; }
    .react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
    .react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
    .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
      border-top-color: #4e4848; }
  .react-datepicker__year-option--selected,
  .react-datepicker__month-option--selected,
  .react-datepicker__month-year-option--selected {
    position: absolute;
    left: 15px; }

.react-datepicker__close-icon {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0px 6px 0px 0px;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: table-cell;
  vertical-align: middle; }
  .react-datepicker__close-icon::after {
    cursor: pointer;
    background-color: #f9be14;
    color: #fff;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    padding: 2px;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    content: "\00d7"; }

.react-datepicker__today-button {
  background: #222222;
  border-top: 1px solid #222222;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left; }

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647; }
  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 3rem;
    line-height: 3rem; }
  @media (max-width: 400px), (max-height: 550px) {
    .react-datepicker__portal .react-datepicker__day-name,
    .react-datepicker__portal .react-datepicker__day,
    .react-datepicker__portal .react-datepicker__time-name {
      width: 2rem;
      line-height: 2rem; } }
  .react-datepicker__portal .react-datepicker__current-month,
  .react-datepicker__portal .react-datepicker-time__header {
    font-size: 2.88rem; }
  .react-datepicker__portal .react-datepicker__navigation {
    border: 1.8rem solid transparent; }
  .react-datepicker__portal .react-datepicker__navigation--previous {
    border-right-color: #696060; }
    .react-datepicker__portal .react-datepicker__navigation--previous:hover {
      border-right-color: #4e4848; }
    .react-datepicker__portal .react-datepicker__navigation--previous--disabled, .react-datepicker__portal .react-datepicker__navigation--previous--disabled:hover {
      border-right-color: #847878;
      cursor: default; }
  .react-datepicker__portal .react-datepicker__navigation--next {
    border-left-color: #696060; }
    .react-datepicker__portal .react-datepicker__navigation--next:hover {
      border-left-color: #4e4848; }
    .react-datepicker__portal .react-datepicker__navigation--next--disabled, .react-datepicker__portal .react-datepicker__navigation--next--disabled:hover {
      border-left-color: #847878;
      cursor: default; }

