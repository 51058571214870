.projects {
  background-color: $front-bg-color;
  color: #ffffff;
  display: flex;
  min-height: 100%;
  overflow: hidden;

  &__sidebar__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.project__content {
  width: 80%;
  @media (max-width: #{$large-devices}){
    width: 100%;
  }
}

.projects__sidebar {
  padding: 2rem 3rem;
  background-color: $second-color;
  width: 20%;
  position: relative;
  min-height: 100vh;

  img  {
    max-width: 12rem;
    width: 100%;
  }

  @media (max-width: #{$large-devices}){
    position: fixed;
    top: 0;
    left: -100vh;
    transition: 1s;
    height: 100vh;
    z-index: 2000;
    width: 40%;
    
    &--open {
      left: 0;
      transition: 1s;
    }
  }

  @media (max-width: #{$small-devices}){
    width: 100%;
    box-sizing: border-box;
  }
}

.projects__sidebar__menu-button {
  display: none;
  background-color: transparent;
  border: none;
  position: absolute;
  right: 1rem;
  top: 0.7rem;
  z-index: 1000;
  cursor: pointer;
  color: #ffffff;

  i {
    font-size: 3rem;
  }

  @media (max-width: #{$large-devices}){
    display: inline;
  }
}

.projects__sidebar__close-button {
  background-color: transparent;
  color: #ffffff;
  border: none;
  position: absolute;
  top: 2rem;
  right: 2rem;
  cursor: pointer;
  display: none;

  i {
    font-size: 3rem;
  }

  @media (max-width: #{$large-devices}){
    display: inline;
  }
}

.projects__title {
  font-size: 1.5rem;
  margin: 3rem 0 2rem 0;
  text-transform: uppercase;
  @extend %font-muli-black;
}

.projects__project-item,
.projects__close-session-button {
  padding: 0.8rem 0;
  
  button {
    color: #ffffff;
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-align: start;
    @extend %font-muli-regular;

    i {
      margin-right: 1rem;
    }

    &:hover {
      color: $dark-color;
    }
  }

  &--selected {
    button {
      color: $dark-color;
    }
  }
}

.projects__close-session-button {
  position: fixed;
  bottom: 1rem;
}

.projects__cameras-container {
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: #{$large-devices}){
    padding: 6rem 1rem 1rem 1rem;
  }
}

.projects__cameras-loading {
  width: 100%;
  height: calc(100Vh - 2rem);
  display: flex;
  align-items: center;
  justify-content: center;
}

.project__camera {

  &--grid {
    width: 32%
  }

  width: 100%;
  border: solid #ffffff 0.3rem;
  box-sizing: border-box;
  cursor: pointer;
  aspect-ratio: 16 / 9;
  position: relative;
  margin: 0.4rem;

  button {
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #ffffff;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    box-sizing: border-box;

    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
    }
  }

  .project__title {
    position: relative;
    padding: 0.8rem 1rem;
    background-color: $second-color;
    span {
      position: relative;
      display: block;
      z-index: 105;
      text-shadow: 0px 2px 4px black;
      text-align: center;
      font-size: 1.3rem;
    }
  }

  @media (max-width: #{$large-devices}){
    &--grid {
      width: 48%
    }
  }

  @media (max-width: #{$small-devices}){
    width: 100%;
  }
}

.projects__dropdown-view-selector {
  &--mobile {
    display: none;
    position: absolute;
    right: 5rem;
    top: 0.5rem;
    @media (max-width: #{$large-devices}){
      display: inline-block;
    }

    @media (max-width: #{$medium-devices}){
      display: none;
    }
  }

  &--desktop {
    display: none;
    position: relative;

    @media screen and (min-width: #{$large-devices}){
      display: inline-block;
    }
  }
}

.projects__mobile-logo {
  display: none;
  max-width: 13rem;
  position: absolute;
  top: 1.4rem;
  left: 1.4rem;
  @media (max-width: #{$large-devices}){
    display: inline-block;
  }
}