.camera{
  height: 100%;
  background-color: $front-bg-color;
  color: #ffffff;
  @include display-flex();
  @include align-items-center();
}

.camera__date{
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  z-index: 10000;
  padding: 0.8rem 1.8rem;
  @include transparent-bg();

  span {
    position: relative;
    z-index: 10;
  }

  @media (max-width: #{$small-devices}) {
    padding: 0.8rem 1rem;
    span {
      font-size: 1rem;
    }
  }
}

.image__container {
  width: 100%;
  height: 100%;
  position: absolute;

  &--loading {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
  }
}