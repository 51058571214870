.forgot-password-form{
  @include popup();
}

.forgot-password-form__container{
  position: relative;
  z-index: 10;
  background-color: $front-font-color;
  padding: 2rem;
  width: 30rem;
}

.forgot-password-form__close-button{
  position: absolute;
  top:0;
  right: 0;
  cursor: pointer;
  background-color: $main-color;
  border: none;
  padding: 0.2rem 0.5rem;
  i{
    color: #ffffff;
    font-size: 2rem;
  }
}