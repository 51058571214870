.footer {
  background-color: $front-button-color;
  position: absolute;
  width: 60rem;
  left: 50%;
  margin-left: -30rem;
  bottom: 0;
  z-index: 10000;
  height: 8rem;
  @include display-flex();
  @include align-items-center();
  @include justify-center();
  @include transparent-bg();

  @media (max-width: #{$extra-large-devices}){
    position: fixed;
    overflow: hidden;
    border-top: 0.2rem solid $second-color;
    width: 100%;
    left: 0;
    margin-left: 0;
  }
}

.footer__button{
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: $front-icon-color;
  margin-right: 5rem;
  z-index: 10;

  i {
    font-size: 3.5rem;
    opacity: 0.7;
    &:hover{
      opacity: 1;
    }
  }
  &:last-child{
    margin: 0;
  }

  &--loading {
    color: $dark-color;
    cursor: auto;
    i {
      &:hover {
        color: $dark-color;
        opacity: 0.7;
      }
    }
  }

  @media (max-width: #{$small-devices}){
    border: 0.2rem solid $second-color;
    margin-right: 1rem;
  }
}

.footer__open-button {
  border-radius: 2.5rem;
  border: none;
  width: 4rem;
  height: 4rem;
  cursor: pointer;
  position: absolute;
  bottom: 2rem;
  left: 2rem;
  background-color: $front-icon-color;
  i {
    font-size: 3rem;
  }
}

.footer--opened{
  -webkit-transition: height 0.3s ease-in-out;
  -moz-transition: height 0.3s ease-in-out;
  -o-transition: height 0.3s ease-in-out;
  transition: height 0.3s ease-in-out;
  @media (max-width: #{$extra-large-devices}){
    height: 8rem;
  }
}

.footer--closed{
  height: 0;
  -webkit-transition: height 0.3s ease-in-out;
  -moz-transition: height 0.3s ease-in-out;
  -o-transition: height 0.3s ease-in-out;
  transition: height 0.3s ease-in-out;
}