.calendar{
  @include popup();
}

.react-datepicker__header__dropdown {
  margin: 1.5rem 0 0.5rem 0;
}

.calendar__calendar-time-picker{
  position: relative;
  z-index: 1;
}

.react-datepicker__month-container{
  background-color: $second-color;
}

.calendar_buttons{
  background-color: $second-color;
  justify-content: space-around;
  padding: 1rem;
  box-sizing: border-box;
  @include display-flex();
}