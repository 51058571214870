.dashboard-header{
  width: 100%;
  height: 10%;
  padding: 0 2rem;
  box-sizing: border-box;
  font-size: 1.8rem;
  border-bottom: solid 0.5rem $second-color;
  @media (max-width: #{$medium-devices}){
    height: 4.5rem;
  }
}

.dashboard-header__logo{
  img{
    width: 100%;
    max-width: 22rem;
    @media (max-width: #{$medium-devices}){
      width: 10rem;
    }
  }
}

.dashboard-header__menu-button {
  display: none;
  background-color: transparent;
  border: none;
  @media (max-width: #{$medium-devices}){
    display: inline;
    position: absolute;
    right: 1rem;
    top: 0.7rem;
    cursor: pointer;
    i {
      font-size: 2.5rem;
    }
  }
}