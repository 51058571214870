@import url('https://fonts.googleapis.com/css?family=Muli:400,700,900|Noto+Sans|Heebo:700');

%font-muli-basic {
  font-family: 'Muli', sans-serif;
}

%font-noto-sans-regular {
  font-family: 'Noto Sans';
  font-weight: 400;
}

%font-muli-regular {
  font-family: 'Muli';
  font-weight: 400;
}

%font-muli-bold {
  font-family: 'Muli';
  font-weight: 700;
}

%font-muli-black {
  font-family: 'Muli';
  font-weight: 900;
}

%font-heebo-bold {
  font-family: 'Heebo', sans-serif;
  font-weight: 700;
}