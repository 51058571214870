.big-logo{
  width: 100%;
  height: 11rem;
  margin-bottom: 2rem;
  @include background-image("../images/Logo-fotomanager.png");
  background-size: contain;
}

.text-center{
  text-align: center;
}

.loading{
  background-image: url("../images/loading.gif");
  display: block;
  width: 5rem;
  height: 5rem;
  background-size: contain;
  margin: auto;
}

/*
 * Forms
 */
.input-text{
  width: 100%;
  height: 4.5rem;
  border: 0.1rem solid $input-border-color;
  font-size: 1.8rem;
  padding: 0 1.5rem;
  box-sizing: border-box;
  background-color: $input-bg-color;
  color: $input-text-color;
  @extend %font-noto-sans-regular;
  &.textarea{
    padding: 1.5rem;
    height: 10rem;
  }
  &.error{
    border-color: $error;
  }
}

.form-group{
  margin-bottom: 2rem;
  width: 100%;
  max-width: 50rem;
  position: relative;
  &.internal-button{
    @include display-flex();
    @include align-items-center();
  }
  button{
    position: absolute;
    right: 1rem;
    font-size: 2rem;
    cursor: pointer;
    background: none;
    border: none;
    color: #777777;
  }

  label{
    margin-bottom: 0.7rem;
    display: block;
    font-size: 1.8rem;
  }

  @media (max-width: #{$medium-devices}){
    max-width: 100%;
  }
}

.white-button{
  border: solid .2rem $second-color;
  background-color: #ffffff;
  padding: 1rem 2rem;
  text-transform: uppercase;
  cursor: pointer;
  &:hover{
    background-color: $main-color;
    border-color: $main-color;
    color: #ffffff;
  }
}

.main-button{
  border: solid .2rem $main-color;
  background-color: $main-color;
  padding: 1rem 2rem;
  text-transform: uppercase;
  color: $second-color;
  cursor: pointer;
  display: inline-block;
}

.form-error{
  color: $error;
  font-style: oblique;
  margin: 1rem 0;
  display: block;
}